





















import Vue from 'vue'
import Card from '@/components/Card.vue'
export default Vue.extend({
  components: {
    Card
  }
})
